// @ts-nocheck
import type * as Types from '@/generated/graphql/global-types.user';

export type ClubDetailFragment = (
  { readonly __typename?: 'clubs' }
  & Pick<Types.Clubs, 'id' | 'name' | 'status' | 'url_name' | 'launch_date' | 'display_settings'>
  & { readonly membership: ReadonlyArray<(
    { readonly __typename?: 'clubs_members' }
    & Pick<Types.Clubs_Members, 'onboarded' | 'onboarding'>
  )> }
);


export const ClubDetailFragmentDoc = `
    fragment ClubDetail on clubs {
  id
  name
  status
  url_name
  launch_date
  display_settings
  membership: members(where: {user_id: {_eq: $userId}}) {
    onboarded
    onboarding
  }
}
    `;